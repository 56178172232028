* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 10px;
}

a,
a:hover,
a:focus {
  text-decoration: none !important;
}

/* MAIN CONTAINER */
.main {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

p {
  color: black;
  font-size: 1.4rem;
  margin-top: 5px;
  line-height: 2.5rem;
  font-weight: 500;
  /* letter-spacing: 0.05rem; */
}

.section-title {
  font-size: 4rem;
  font-weight: 300;
  color: black;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  text-align: center;
}

.cta {
  display: inline-block;
  padding: 10px 30px;
  color: crimson;
  background-color: transparent;
  border: 2px solid crimson;
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 1rem;
  margin-top: 30px;
  transition: 0.3s ease;
  transition-property: background-color, color;
}

.cta:hover {
  color: white;
  background-color: crimson;
  color: #000000b0;
}

.set-size {
  font-size: 1.8rem;
}
/* 
nav{
  background-color: rgba(0, 0, 0, 0.904);
} */

/* .set-size li{
  color:rgba(0, 0, 0, 0.904);
} */

/* .nav-list {
  position: absolute;
  background-color: black;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  overflow-x: hidden;
} */
/* HERO SECTION */
#hero {
  background-image: url(./images/awards2.jpg);
  background-size: cover;
  background-position: top center;
  position: relative;
  height: auto;
  z-index: 1;
}

#hero::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.925);
  opacity: 0.7;
  z-index: -1;
}

#hero .hero {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 50px;
  justify-content: flex-start;
}

#hero h1 {
  display: block;
  width: fit-content;
  font-size: 4rem;
  position: relative;
  color: transparent;
  animation: text_reveal 0.5s ease forwards;
  animation-delay: 1s;
}

#hero h1:nth-child(1) {
  animation-delay: 1s;
}
#hero h1:nth-child(2) {
  animation-delay: 2s;
}
#hero h1:nth-child(3) {
  animation: text_reveal_name 0.5s ease forwards;
  animation-delay: 3s;
}

#hero h1 span {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0%;
  background-color: crimson;
  animation: text_reveal_box 1s ease;
  animation-delay: 1s;
}
#hero h1:nth-child(1) span {
  animation-delay: 0.5s;
}
#hero h1:nth-child(2) span {
  animation-delay: 1.5s;
}
#hero h1:nth-child(3) span {
  animation-delay: 2.5s;
}

/* END HERO SECTION */

/* START OF SERVICES */
#services .services {
  flex-direction: column;
  text-align: center;
  max-width: 1500px;
  margin: 0 auto;
  padding: 100px 0;
}

#services .service-top {
  width: 300px;
  margin: 0 auto;
}

#services .service-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 50px;
}

#services .service-top p {
  color: black;
  font-size: 1.4rem;
  margin-top: 5px;
  line-height: 2.5rem;
  font-weight: 500;
  letter-spacing: 0.05rem;
}

#services .service-item {
  flex-basis: 80%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 30px;
  border-radius: 10px;
  background-image: url(./images/IMG_0150.jpg);
  background-size: cover;
  margin: 10px 5%;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

#services .service-item::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(60deg, #030507 0%, #485563 100%);
  opacity: 0.9;
  z-index: -1;
}

#services .service-bottom .icon {
  height: 80px;
  width: 80px;
  margin-bottom: 20px;
}

/* #services .service-bottom .icon img{
  height: 100%;
  width: 100%;
  object-fit: cover;
} */

#services .service-item h2 {
  font-size: 2rem;
  color: white;
  margin-bottom: 10px;
  text-transform: uppercase;
}

#services .service-item p {
  /* font-size: 1.4rem; */
  /* line-height: 1.9rem; */
  color: white;
  text-align: left;
}
/* END OF SERVICES */

/* START OF PROJECTS */
 .projects {
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  padding: 100px 0;
}

#projects .projects-header h1 {
  margin-bottom: 50px;
}

#projects .all-projects {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#projects .project-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 80%;
  margin: 20px auto;
  overflow: hidden;
  border-radius: 10px;
}

#projects .project-info {
  padding: 30px;
  flex-basis: 50%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
  color: white;
}

#projects .project-info h1 {
  font-size: 4rem;
  font-weight: 500;
}

#projects .project-info h2 {
  font-size: 1.8rem;
  font-weight: 400;
  margin-top: 10px;
}

#projects .project-info p {
  color: white;
}

#projects .project-img {
  flex-basis: 50%;
  height: 300px;
  /* width: 300px; */
  overflow: hidden;
  position: relative;
}

#projects .project-img::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
  opacity: 0.4;
}

#projects .project-img img {
  transition: 0.3s ease transform;
}

#projects .project-item:hover .project-img img {
  transform: scale(1.1);
}
/* END OF PROJECTS */

/* START OF ABOUT SECTION */
#about .about {
  flex-direction: column-reverse;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 100px 20px;
}

#about .col-left {
  width: 250px;
  height: 360px;
}
#about .col-right {
  width: 100%;
}

#about .col-right h2 {
  font-size: 1.8rem;
  font-weight: 500;
  letter-spacing: 0.2rem;
  margin-bottom: 20px;
}

#about .col-right p {
  margin-bottom: 20px;
}

#about .col-right .cta {
  color: black;
  margin-bottom: 50px;
  padding: 10px 20px;
  font-size: 2rem;
}

#about .col-left .about-img {
  height: 100%;
  width: 100%;
  position: relative;
  border: 10px solid white;
}

#about .col-left .about-img::after {
  content: "";
  position: absolute;
  left: -33px;
  top: 19px;
  height: 98%;
  width: 98%;
  border: 2px solid crimson;
  z-index: -1;
}
/* END OF ABOUT SECTION */

/* START OF CONTACT SECTION */
#contact .contact {
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
}

#contact .contact-items {
  width: 300px;
}

#contact .contact-item {
  width: 80%;
  text-align: center;
  border-radius: 10px;
  padding: 30px;
  margin: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 0px 18px 0 #0000002c;
  transition: 0.3s ease box-shadow;
}

#contact .contact-item:hover {
  box-shadow: 0px 0px 5px 0 #0000002c;
}

#contact .icon {
  width: 70px;
  margin: 0 auto;
  margin-bottom: 10px;
}

#contact .contact-info h1 {
  font-size: 2.5rem;
  font-weight: 500;
  margin-bottom: 5px;
}

#contact .contact-info h2 {
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 2rem;
}
/* END OF CONTACT SECTION */

/* START OF BLOGS PAGES */
#blogs .blogs {
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  padding: 100px 0;
}

#blogs .blogs-header h1 {
  margin-bottom: 50px;
}

#blogs p{
  text-indent: 50px;
}

#blogs img.pot{
  height: 557.80px;
  width: 446px;
  /* object-fit: cover; */
}

#search-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: rgb(240, 240, 240); */
   padding: 10px;
  width: 100%;
}

#search-bar .search-input {
  width: 100%;
  max-width: 300px;
  padding: 8px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  color: rgb(55, 138, 7);
  background-color: rgba(201, 196, 196, 0.664);
}


/* END OF BLOGS PAGES */

/* KEYFRAMES */
@keyframes text_reveal_box {
  50% {
    width: 100%;
    left: 0;
  }

  100% {
    width: 0;
    left: 100%;
  }
}
@keyframes text_reveal {
  100% {
    color: white;
  }
}
@keyframes text_reveal_name {
  100% {
    color: crimson;
    font-weight: 500;
  }
}

/* END KEYFRAMES */

/* MEDIA QUERY FOR TABLET */
@media only screen and (min-width: 768px) {
  .cta {
    font-size: 2.5rem;
    padding: 20px 60px;
  }
  h1.section-title {
    font-size: 6rem;
  }

  /* HERO */
  #hero h1 {
    font-size: 7rem;
  }
  /* END HERO */

  /* PROJECTS */
  #projects .project-item {
    flex-direction: row;
    height: 400px;
    margin: 0;
    /* padding: 5px; */
    width: 100%;
    border-radius: 0;
  }

  #projects .project-item:nth-child(even) {
    flex-direction: row-reverse;
  }

  #projects .all-projects .project-info {
    height: 100%;
  }

  #projects .all-projects .project-img {
    height: 100%;
  }

  /* END PROJECTS */

  /* ABOUT */
  #about .about {
    flex-direction: row;
  }

  #about .col-left {
    width: 600px;
    height: 400px;
    padding-left: 60px;
  }

  #about .col-left .about-img::after {
    left: -45px;
    top: 34px;
    height: 98%;
    width: 98%;
    border: 10px solid crimson;
  }

  #about .col-right {
    text-align: left;
    padding: 30px;
  }

  #about .col-right h1 {
    text-align: left;
  }
  /* END ABOUT */

  /* CONTACT */
  #contact .contact {
    min-height: 20vh;
  }

  #contact .contact-items {
    width: auto;
    padding: 20px;
    margin: 0;
  }

  #contact .contact-item {
    margin: 20px;
  }

  #contact .contact-item .icon {
    width: 100px;
    height: 100px;
  }

  #contact .contact-info h2 {
    font-size: 1.15rem;
  }
  /* END CONTACT */

  #services .services .service-item {
    min-height: 35vh;
  }
}

.search-bar {
  padding: 5px;
}

.search-input {
  max-width: 200px;
  font-size: 14px;
}
/* END MEDIA QUERY FOR TABLET */
